import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { GetTimeUntilCounter } from './components/get-time-until';
import { TotalCO2EmissionsCounter } from './components/total-co2-emissions';
import { HumanInducedWarmingCounter } from './components/human-induced-warming';
import { EnergyMix } from './components/energy-mix';

import './components/main.scss';

const targetDate = process.env.REACT_APP_TARGET_DATE || '2032-04-22T14:45:17';
const baseDate = process.env.REACT_APP_HUMAN_INDUCED_WARMING_BASE_DATE || '2020-12-15T00:00:00';

const co2EmissionsBaseDate = process.env.REACT_APP_C02_EMISSIONS_BASE_DATE || '2020-12-15T00:00:00';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/get-time-until">
            <GetTimeUntilCounter targetDate={targetDate} />
          </Route>
          <Route path="/total-emissions">
            <TotalCO2EmissionsCounter baseDate={co2EmissionsBaseDate} />
          </Route>
          <Route path="/human-induced-warming">
            <HumanInducedWarmingCounter baseDate={baseDate} />
          </Route>
          <Route path="/energy-mix">
            <EnergyMix />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <ul>
        <li>
          <Link to="/get-time-until">Countdown Timer</Link>
        </li>
        <li>
          <Link to="/total-emissions">Total CO2 Emissions</Link>
        </li>
        <li>
          <Link to="/human-induced-warming">Human Induced Warming</Link>
        </li>
        <li>
          <Link to="/energy-mix">Energy Mix</Link>
        </li>
      </ul>
    </div>
  );
}
