import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';

import { EnergyMixItem } from './energy-mix-item';
import { orderDescending } from './energy-mix-util';
import { getData } from './get-data';

export function EnergyMix() {
  const [energyMixValues, setEnergyMixValues] = useState([]);
  const [responseErrored, setResponseErrored] = useState(false);
  const fiveMinutes = 1000 * 60 * 5;
  const refreshRate = process.env.REACT_APP_ENERGY_MIX_REFRESH_RATE || fiveMinutes;

  useEffect(() => {
    async function fetchData() {
      const response = await getData();
      if (!response.data.hasOwnProperty('error')) {
        const orderedResponse = orderDescending(response);
        setEnergyMixValues(orderedResponse);
      } else {
        setResponseErrored(true);
      }
    }
    fetchData();
    const interval = setInterval(fetchData, refreshRate);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="sdc-climate-counter__gradient">
      <div className="sdc-energy-mix__glass-effect"></div>
      <div className="sdc-energy-mix__contents">
        <h3 className={ClassNames('sdc-climate-counter__heading', 'mid-scale')}>Real Time Power Mix GB</h3>
        {responseErrored === true ? (
          <div className="sdc-climate-counter__heading">Unable to retrieve data</div>
        ) : (
          <div className="sdc-energy-mix__grid-container">
            <EnergyMixItem energyMixValues={energyMixValues} />
          </div>
        )}
        <p className={ClassNames('sdc-climate-counter__link', 'small-scale')}>
          Source:&nbsp;
          <a href="https://carbonintensity.org.uk/" target="blank">
            National Grid ESO
          </a>
        </p>
      </div>
    </div>
  );
}
