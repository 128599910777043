import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { getHumanInducedWarming } from './calculate-warming';

import '../styles/counters.scss';
import './human-induced-warming.scss';

export function HumanInducedWarmingCounter({ baseDate }) {
  const [humanInducedWarming, setHumanInducedWarming] = useState(
    getHumanInducedWarming(new Date(baseDate), new Date())
  );
  useEffect(() => {
    setTimeout(() => {
      setHumanInducedWarming(getHumanInducedWarming(new Date(baseDate), new Date()));
    }, 1000);
  });

  return (
    <div className="sdc-climate-counter__gradient">
      <div className="sdc-climate-counter__glass-effect"></div>
      <div className="sdc-climate-counter__contents">
        <h3 className={ClassNames('sdc-climate-counter__heading', 'mid-scale')}>Global warming since 1880</h3>
        <p className={ClassNames('sdc-climate-countdown__warming-text', 'large-scale')}>
          <span className="sdc-climate-countdown__warming-value">+{humanInducedWarming.toFixed(9)}</span>°C
        </p>
        <p className={ClassNames('sdc-climate-counter__link', 'small-scale')}>
          Source:&nbsp;
          <a href="https://www.globalwarmingindex.org" target="blank">
            Environmental Change Institute
          </a>
          , University of Oxford
        </p>
      </div>
    </div>
  );
}

HumanInducedWarmingCounter.propTypes = {
  baseDate: PropTypes.string,
};
