import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { getTotalCO2Emissions } from './calculate-co2-emissions';

import '../styles/counters.scss';

export function TotalCO2EmissionsCounter({ baseDate }) {
  const startDate = new Date(baseDate);
  const [totalCO2Emissions, setTotalCO2Emissions] = useState(getTotalCO2Emissions(startDate, new Date()));
  useEffect(() => {
    const interval = setInterval(() => {
      setTotalCO2Emissions(getTotalCO2Emissions(startDate, new Date()));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="sdc-climate-counter__gradient">
      <div className="sdc-climate-counter__glass-effect"></div>
      <div className="sdc-climate-counter__contents">
        <h3 className={ClassNames('sdc-climate-counter__heading', 'mid-scale')}>
          Total CO<sub>2</sub> Emissions (Million Tonnes)
        </h3>
        <p className={ClassNames('sdc-climate-countdown__warming-text', 'large-scale')}>{totalCO2Emissions}</p>
        <p className={ClassNames('sdc-climate-counter__link', 'small-scale')}>
          Source:&nbsp;
          <a href="https://www.globalwarmingindex.org" target="blank">
            Environmental Change Institute
          </a>
          , University of Oxford
        </p>
      </div>
    </div>
  );
}

TotalCO2EmissionsCounter.propTypes = {
  baseDate: PropTypes.string,
};
