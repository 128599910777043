import { getSecondsBetweenDates } from '../../utils';

function getTotalCO2Emissions(startDate, endDate) {
  const secondsSinceBaseDate = getSecondsBetweenDates(startDate, endDate);
  const totalCO2Emissions = (secondsSinceBaseDate * 365 * 44) / 12 + 2394.0 * 1000000000;
  const totalCO2EmissionsInMillions = totalCO2Emissions / 1000000;
  const formattedWithCommas = totalCO2EmissionsInMillions.toLocaleString('en-GB', { minimumFractionDigits: 3 });
  return formattedWithCommas;
}

export { getTotalCO2Emissions };
