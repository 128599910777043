import axios from 'axios';

export async function getData() {
  const url = 'https://api.carbonintensity.org.uk/generation';
  return axios
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((error) => console.error(`Error: ${error}`));
}
