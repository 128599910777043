import { Interval } from 'luxon';

const formatRemainingTime = (remainingTime) => {
  const formatTime = (time) => (time < 10 ? `0${time}` : `${time}`);
  const timeLeft = {
    yrs: formatTime(remainingTime.years),
    mths: formatTime(remainingTime.months),
    days: formatTime(remainingTime.days),
    hrs: formatTime(remainingTime.hours),
    mins: formatTime(remainingTime.minutes),
    secs: formatTime(remainingTime.seconds),
  };
  return timeLeft;
};

const getRemainingTime = (current, target) => {
  const interval = Interval.fromDateTimes(current, target);
  const remainingTime = interval
    .toDuration(['years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'])
    .toObject();
  return formatRemainingTime(remainingTime);
};

export { getRemainingTime, formatRemainingTime };
