import React from 'react';
import ClassNames from 'classnames';
import './energy-mix.scss';

export function EnergyMixItem(props) {
  const { energyMixValues } = props;

  if (energyMixValues.length > 0) {
    return energyMixValues.map((item, index) => {
      return (
        <span key={index} className="sdc-energy-mix__item">
          <span className={ClassNames('sdc-energy-mix__value', 'large-scale')}>{`${item.perc}%`}</span>
          <span className={ClassNames('sdc-energy-mix__unit', 'mid-scale')}>{item.fuel.toUpperCase()}</span>
        </span>
      );
    });
  } else {
    return <p>Fetching data...</p>;
  }
}
