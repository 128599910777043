import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { getRemainingTime } from './calculate-interval';

import '../styles/counters.scss';
import './calculate-interval.scss';

export function GetTimeUntilCounter({ targetDate }) {
  const [timeLeft, setTimeLeft] = useState(getRemainingTime(new Date(), new Date(targetDate)));
  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(getRemainingTime(new Date(), new Date(targetDate)));
    }, 1000);
  });
  function createTimerComponent() {
    const timerComponent = [];
    Object.keys(timeLeft).forEach((interval, index) => {
      timerComponent.push(
        <span key={index}>
          <span className={ClassNames('sdc-climate-countdown__value', 'large-scale')}>{timeLeft[interval]}</span>
          <span className={ClassNames('sdc-climate-countdown__unit', 'mid-scale')}>{interval.toUpperCase()}</span>
        </span>
      );
    });
    return timerComponent;
  }

  function createTimerTextStringForScreenreaders() {
    const timerString = `${timeLeft.yrs} years ${timeLeft.mths} months ${timeLeft.days} days ${timeLeft.hrs} hours ${timeLeft.mins} minutes ${timeLeft.secs} seconds`;

    return timerString;
  }

  return (
    <div className="sdc-climate-counter__gradient">
      <div className="sdc-climate-counter__glass-effect"></div>
      <div className="sdc-climate-counter__contents">
        {new Date() > new Date(targetDate) ? (
          <div className="sdc-climate-counter__heading">The date has already passed!</div>
        ) : (
          <Fragment>
            <h3 className={ClassNames('sdc-climate-counter__heading', 'mid-scale')}>Time left to 1.5&deg; Celsius</h3>
            <p className="sdc-climate-counter__countdown" aria-hidden={true}>
              {createTimerComponent()}
            </p>
            <p className="visually-hidden">{createTimerTextStringForScreenreaders()}</p>
            <p className={ClassNames('sdc-climate-counter__link', 'small-scale')}>
              Source:&nbsp;
              <a href="https://www.concordia.ca/news/climateclock.html" target="blank">
                Concordia Climate Clock
              </a>
            </p>
          </Fragment>
        )}
      </div>
    </div>
  );
}

GetTimeUntilCounter.propTypes = {
  targetDate: PropTypes.string,
};
