import { getSecondsBetweenDates } from '../../utils';

const TEMPERATURE_AT_BASE_DATE = 1.2163;
const TEMP_INCREASE_PER_SECOND_PREDICTION = 0.00000000075481;

function getHumanInducedWarming(baseDate, now) {
  const secondsSinceBaseDate = getSecondsBetweenDates(baseDate, now);
  const humanInducedWarming = TEMPERATURE_AT_BASE_DATE + TEMP_INCREASE_PER_SECOND_PREDICTION * secondsSinceBaseDate;
  return humanInducedWarming;
}

export { getHumanInducedWarming };
